import React from 'react';
import "./src/styles/global.css"
import { AuthProvider } from "./src/components/Firebase/authContext"
import { SessionProvider } from "./src/components/Firebase/sessionContext"


export const wrapRootElement = ({ element }) => (
  <SessionProvider>
  <AuthProvider>
    {element}
  </AuthProvider>
  </SessionProvider>
);