import React, { createContext, useContext, useState } from 'react';

interface SessionContextType {
    sessionData: { [key: string]: any };
    updateSessionData: (newData: { [key: string]: any }) => void;
  }
  

  const defaultContextValue: SessionContextType = {
    sessionData: {}, 
    updateSessionData: () => {}, 
  };
  
  const SessionContext = createContext<SessionContextType>(defaultContextValue);
export const SessionProvider = ({ children }: any) => {
  const [sessionData, setSessionData] = useState(() => {

    const savedData = localStorage.getItem('sessionData');
    return savedData ? JSON.parse(savedData) : {};
  });

  const updateSessionData = (newData : any) => {
    setSessionData((prevData : any) => {
      const updatedData = { ...prevData, ...newData };

      localStorage.setItem('sessionData', JSON.stringify(updatedData));
      return updatedData;
    });
  };

  const value = {
    sessionData,
    updateSessionData,
  };

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export const useSession = () => useContext(SessionContext);