import { initializeApp } from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile} from 'firebase/auth';
import { doc, setDoc,getFirestore, collection, query, getDoc, where, getDocs } from 'firebase/firestore';
import { getDatabase, ref, get, set } from 'firebase/database';

const firebaseConfig = {
    apiKey: process.env.GATSBY_API_KEY,
    authDomain: process.env.GATSBY_AUTH_DOMAIN,
    projectId: process.env.GATSBY_PROJECT_ID,
    storageBucket:  process.env.GATSBY_STORAGE_BUCKET,
    appId: process.env.GATSBY_APP_ID,
    messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
};

const app =  initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const realtimedb = getDatabase();
export const addReview = async(idWorld:any, data: any) => {
    const userReviewsPath = `pendingratings/${idWorld}/reviews`;
    const newReviewRef = doc(db, userReviewsPath,auth.currentUser?.uid.toString() ?? 'none');
    await setDoc(newReviewRef, {...data, username: auth.currentUser?.displayName, uid: auth.currentUser?.uid, worldId: idWorld});
}

export const doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    createUserWithEmailAndPassword(auth,email, password)

export const doSignInWithEmailAndPassword = (email: string, password: string) =>
    signInWithEmailAndPassword(auth,email, password)

export const doSignOut = () => {
    localStorage.setItem('sessionData', '')
    auth.signOut();
}

export const getUser = () => {
    return auth.currentUser;
};

export const checkIfUsernameExists = async (username: string) => {
    const usernameRef = ref(realtimedb, 'username/' + username);
    const snapshot = await get(usernameRef);
    if(!snapshot.exists()){
        await set(usernameRef, username);
    }
    return snapshot.exists();
}


export const updateUser = async(data: any) => {
    if (auth.currentUser) {
        updateProfile(auth.currentUser, data).then(() => {
            console.log("updated");
        });
    }
};



export const checkUserCommentForWorld = async (idWorld: string, date: number) => {
    const querySnapshot = await getDocs(query(
        collection(db, `pendingratings/${idWorld}/reviews`), 
        where("uid", "==", auth.currentUser?.uid),
        where("date", ">", date)
    ));
    return !querySnapshot.empty ? querySnapshot.docs[0].data() : false;
};