import React, {ReactNode, createContext, useContext} from 'react';

import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

export const AuthContext = createContext<any>(null);
interface AuthProviderProps {
  children: ReactNode;
}
export const AuthProvider = ({ children } : AuthProviderProps) => {
  const [user, loading, error] = useAuthState(auth);  
  return (
    <AuthContext.Provider value={{ user, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);